<template>
    <div class="detail" :class="$mq">
        <div class="top-actions">
            <div class="return-btn" @click="back()"></div>
            <div class="titl">
                <h2 class="name">{{ title }}</h2>
            </div>
            <!-- :label="$t('vtf.datatable.search')" -->
            <input v-if="!hasError" type="text" class="search" v-model="search" :placeholder="$t('vtf.datatable.search')" />
            <div @click="editColumns" class="edit-columns"></div>
            <div @click="sendAddedit('add')" v-if="this.userAppAccess && checkPermissions()" class="add-report"></div>
        </div>
        <div class="content hide-scrollbar" :class="{ 'no-scroll': searchedValues.length == 0 && !hasError }">
            <!-- DataTable -->
            <div class="datatable-container" id="datatable" v-if="searchedValues.length > 0">
                <DataTable @sort="sortTable" :headers="headers" :values="searchedValues" @sendAddedit="sendAddedit" />
            </div>
            <div class="pagination" v-if="connection && searchedValues.length > 0 && (page > 1 || num_items * page < results)">
                <div v-if="page > 1" class="item prev" @click="page_prev()"></div>
                <div v-if="num_items * page < results" class="item next" @click="page_next()"></div>
            </div>

            <template v-else-if="searchedValues.length == 0">
                <EmptyPage v-if="!hasError && connection && itemsLoaded" @buttonAction="sendAddedit" :title="$t('empty_pages.record_title')" type="record" :description="$t('empty_pages.record_description')" :buttonText="$t('empty_pages.start')"></EmptyPage>

                <ConnectionBox gType="registerConnection" @reload="reloadRegister" v-else-if="!checkConnetion && !itemsLoaded"></ConnectionBox>
            </template>

            <EmptyPage v-if="hasError" :buttonText="$t('empty_pages.return_logs')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_record')"></EmptyPage>
        </div>
    </div>
</template>

<script>
import DataTable from '../ui/DataTable.vue'
import EmptyPage from '@/components/layout/EmptyPage'
import { FORMAT } from '@/constants'
import ConnectionBox from '@/components/domain/ui/Box.vue'

export default {
    components: { EmptyPage, DataTable, ConnectionBox },
    props: {
        register: { type: Object },
        hasError: { type: undefined, default: false },
        detailId: { type: undefined, default: null }
    },
    data() {
        return {
            search: '',
            pagination: {
                sortBy: 'dateUnix',
                rowsPerPage: -1,
                descending: true
            },
            hidden_columns: localStorage.col_register ? JSON.parse(localStorage.col_register) : [],
            FORMAT: FORMAT,
            page: 1,
            num_items: 10,
            columnSortedDesc: false,
            columnSortedAsc: false,
            headersConf: localStorage.getItem(`headersConf_${this.detailId}`) ? JSON.parse(localStorage.getItem(`headersConf_${this.detailId}`)) : [],
            checkConnetion: false
        }
    },
    created() {
        const num_page = this.$store.getters['register/getNumberPage']
        if (num_page) {
            this.page = num_page
        }
        this.checkConnetion = fnCheckConnection()
    },
    mounted() {
        this.checkConnetion = fnCheckConnection()
    },
    computed: {
        connection() {
            return this.$store.getters['getConnState']
        },
        itemsLoaded() {
            return this.$store.getters['register/getItemsLoaded']
        },
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },
        title() {
            if (Object.keys(this.register).length > 0 && fnCheckConnection()) {
                let keys = Object.keys(this.register)
                return keys.length > 0 ? this.register[keys[0]].title : ''
            } else {
                var template = this.$store.getters['register/getTemplateById'](this.$route.params.id)
                if (template) {
                    return template.name
                }
                return ''
            }
        },
        template() {
            return this.$store.getters['register/getTemplateById'](this.$route.params.id)
        },
        headers: {
            get() {
                let schema = this.$store.getters['register/getHeaders']

                const headers = []
                headers.push({
                    text: this.$t('register.headers.date'),
                    value: 'date',
                    sortable: true,
                    visible: true,
                    blocked: true,
                    width: '300px'
                })
                headers.push({
                    text: this.$t('register.headers.employee'),
                    value: 'employee',
                    sortable: true,
                    visible: true,
                    blocked: true
                })

                const schemaValues = Object.values(schema)
                if (schemaValues.length > 0) {
                    const titlesSchema = schemaValues.filter((header) => header.type !== 8 && header.type !== 9).map((value) => value)
                    titlesSchema.forEach((header) => {
                        headers.push({
                            text: header.title,
                            value: header.id,
                            sortable: true,
                            type: header.type,
                            visible: true,
                            blocked: false
                        })
                    })
                }

                if (this.headersConf.length > 0) {
                    if (this.headersConf.length !== headers.length) {
                        //Ver campos que tiene headerConf y modificarlos en headers
                        this.headersConf.forEach((headerConf) => {
                            const index = headers.findIndex((header) => header.value === headerConf.value)
                            if (index !== -1) {
                                headers[index].visible = true
                            }
                            headers[index].visible = headerConf.visible
                        })
                        return JSON.parse(JSON.stringify(headers))
                    }
                    return JSON.parse(JSON.stringify(this.headersConf))
                }

                return headers
            },
            set(val) {
                //this.headers = val
            }
        },
        results() {
            return this.$store.getters['register/getNumberItems'] ? this.$store.getters['register/getNumberItems'] : false
        },
        searchedValues() {
            /*const resultFiltered = Object.values(this.register).reduce((acc, obj) => {
                if (obj.template_id === self.$route.params.id) {
                    acc[obj.id] = obj
                }
                return acc
            }, {})
            */
            const self = this
            const resultFiltered = Object.values(this.register).reduce((acc, obj) => {
                for (let itemid in obj.items) {
                    if (self.search) {
                        if (obj.items[itemid].value !== null && obj.items[itemid].value !== undefined && obj.items[itemid].value.toString().toLowerCase().includes(self.search.toLowerCase())) {
                            console.log(obj)
                            if (obj.items[itemid].type != 14 && obj.items[itemid].type != 5) {
                                acc[obj.id] = obj
                                break
                            }
                        }
                    } else if (!self.search) {
                        if (obj.template_id === self.$route.params.id) {
                            acc[obj.id] = obj
                            break
                        }
                    }
                }
                return acc
            }, {})

            const hashIDs = resultFiltered ? Object.keys(resultFiltered) : false

            if (fnCheckConnection()) {
                return this.fillTable(this.register, hashIDs)
            } else {
                return this.fillTable(resultFiltered, hashIDs)
            }
        }
    },
    methods: {
        checkPermissions() {
            const template = this.template
            const userRole = this.$store.getters['employee/getRol'](this.user.rol)
            if (userRole.level >= 8) {
                return true
            }

            if (template && template.config && !(template.config.nRoles || template.config.roles)) {
                return true
            }

            if (template && template.config && template.config.nRoles) {
                const nRoles = template.config.nRoles
                let isValid = false

                nRoles.forEach((role) => {
                    if (role.roleId == userRole.id && role.permissions.includes('w')) {
                        isValid = true
                        return
                    }
                })
                return isValid
            }

            return true
        },
        editColumns() {
            const self = this
            const headersCopy = JSON.parse(JSON.stringify(this.headers))
            this.$popup.tableColumn({
                title: 'Personalizar columnas',
                textSave: 'Guardar',
                headers: this.headers,
                routePop: self.$route.name,
                callSave: (obj) => {
                    self.headersConf = obj.$data.headers
                    localStorage.setItem(`headersConf_${self.detailId}`, JSON.stringify(obj.$data.headers))
                    self.$popup.close()
                    self.$forceUpdate()
                },
                headers: headersCopy,
                callCancel: function () {
                    this.$popup.close()
                }
            })
        },
        sortTable(obj) {
            this.columnSortedDesc = obj.columnSortedDesc
            this.columnSortedAsc = obj.columnSortedAsc
        },
        prepareTable(register) {
            var values = []
            for (var i in register) {
                var raw = register[i]
                var row = {}
                // row.edit = "";
                // row.select = "";
                // row.dateUnix = raw.created_date ? moment(raw.created_date).unix() : '-'
                row.date = raw.created_date ? moment(raw.created_date).format('DD/MM/YYYY HH:mm') : '-'

                row.employee = raw.employee && raw.employee != null ? raw.employee.name : '-'
                row.id = raw.id ? raw.id : ''
                row.arrow = raw.status ? 1 : 0
                row.status = raw.status
                row.statusB = raw.status

                for (var j in raw.items) {
                    var item = raw.items[j]
                    row[item.item_id + '_type'] = item.type
                    row[item.item_id] = item.value
                    if (item.type === 11) {
                        row[item.item_id + '_config'] = item.config ? item.config : ''
                    }
                }

                values.push(row)
            }
            //console.log('prepareTable', values)
            return values
        },
        fillTable(arr, index = false) {
            var values = []
            var self = this
            for (var i in arr) {
                if (!index || index.includes(i)) {
                    var raw = arr[i]
                    var row = {}
                    row.dateUnix = raw.created_date ? moment(raw.created_date).unix() : '-'

                    row.date = raw.created_date ? '<span style="display: none">' + moment(raw.created_date).unix() + '</span>' + `<span class="bold ${this.$mq} ${raw.requireSync ? 'require-sync-icon' : ''}">` + moment(raw.created_date).format('DD/MM/YYYY HH:mm') + '</span>' : '-'

                    /* row.employee = raw.employee && raw.employee != null ? '<div class="img-container"> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee.avatar + '" /><div class="name">' + raw.employee.name + '</div> </div>' : '-' */
                    if (raw.employee && raw.employee.avatar && raw.employee.avatar.includes('http')) {
                        row.employee = '<div class="img-container"> <img class="main-img" src="' + raw.employee.avatar + '" /><div class="name">' + raw.employee.name + '</div> </div>'
                    } else if (raw.employee && raw.employee.avatar && raw.employee.avatar.includes('file')) {
                        row.employee = '<div class="img-container"> <img class="main-img" src="' + raw.employee.avatar + '" /><div class="name">' + raw.employee.name + '</div> </div>'
                    } else if (raw.employee && raw.employee.avatar && raw.employee.avatar.includes('svg')) {
                        row.employee = '<div class="img-container"> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee.avatar + '" /><div class="name">' + raw.employee.name + '</div> </div>'
                    }
                    row.id = raw.id ? raw.id : ''
                    row.arrow = raw.status ? '<div class="arrow-container"> <img class="arrow-img" src="' + css_image_url + 'right_neutro.svg" />' : '<div class="arrow-container"> <div class="bullet-img"> </div> <img class="arrow-img" src="' + css_image_url + 'right_second.svg" />'
                    row.status = raw.status
                    row.requireSync = raw.requireSync ? raw.requireSync : false

                    for (var j in raw.items) {
                        var item = raw.items[j]
                        row[item.item_id + '_type'] = item.type
                        row[item.item_id] = item.value
                        if (item.type === 11) {
                            row[item.item_id + '_config'] = item.config ? item.config : ''
                        }
                    }

                    values.push(row)
                }
            }

            // SORT FOR COLUMNS ASC DESC
            if (self.columnSortedDesc && !self.columnSortedAsc) {
                values = values.sort(function (a, b) {
                    if (b[self.columnSortedDesc] && isNaN(b[self.columnSortedDesc]) && a[self.columnSortedDesc] && isNaN(a[self.columnSortedDesc])) {
                        return a[self.columnSortedDesc].localeCompare(b[self.columnSortedDesc])
                    } else return (b[self.columnSortedDesc] !== null ? b[self.columnSortedDesc] : -Infinity) - (a[self.columnSortedDesc] !== null ? a[self.columnSortedDesc] : -Infinity)
                })
            } else if (self.columnSortedAsc) {
                values = values.sort(function (a, b) {
                    if (b[self.columnSortedAsc] && isNaN(b[self.columnSortedAsc]) && a[self.columnSortedAsc] && isNaN(a[self.columnSortedAsc])) {
                        return b[self.columnSortedAsc].localeCompare(a[self.columnSortedAsc])
                    } else return (a[self.columnSortedAsc] !== null ? a[self.columnSortedAsc] : -Infinity) + (b[self.columnSortedAsc] !== null ? b[self.columnSortedAsc] : -Infinity)
                })
            }
            return values
        },

        filter() {
            if (fnCheckConnection()) {
                var self = this
                var params = {
                    id: this.$route.params.id,
                    number_pages: this.page,
                    number_items: this.num_items
                }
                this.$overlay.loading()
                this.$store.dispatch('register/loadItemsPagination', params).then(function () {
                    self.$overlay.hide()
                })
            }
        },
        reloadRegister() {
            if (fnCheckConnection()) {
                var self = this
                var params = {
                    id: this.$route.params.id,
                    number_pages: this.page,
                    number_items: this.num_items
                }
                this.$overlay.loading()
                this.$store.dispatch('register/loadItemsPagination', params).then(function () {
                    self.$overlay.hide()
                })
            }
        },
        scrollDatataTable() {
            setTimeout(() => {
                document.getElementById('datatable').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }, 50)
        },
        page_prev() {
            this.search = ''
            this.page -= 1
            this.filter()
            this.scrollDatataTable()
        },
        page_next() {
            this.search = ''
            this.page += 1
            this.filter()
            this.scrollDatataTable()
        },
        back() {
            this.page = 1
            this.$store.commit('register/setNumberPage', 1)
            this.$emit('goBack')
        },
        filterColumns() {
            var self = this
            this.$filter({
                list: this.headers,
                selected: self.hidden_columns,
                callback: function (d) {
                    self.hidden_columns = [...d]
                    self.saveFilter(self.hidden_columns)
                }
            })
            this.$filter.show()
        },

        saveFilter(filter) {
            if (localStorage) {
                localStorage.col_register = JSON.stringify(filter)
            }
        },

        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },

        sendAddedit(id) {
            this.$emit('sendedAddedit', id)
        },

        format(value, type) {
            switch (parseInt(type)) {
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                case FORMAT.YESNONC:
                    return value

                case FORMAT.DATE:
                    return moment(value).format('DD/MM/YYYY')

                case FORMAT.DATETIME:
                    return value ? moment(value).format('DD/MM/YYYY HH:mm') : ''

                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    if (value && typeof value === 'object') {
                        var html = ''
                        Object.values(value).forEach((img) => {
                            html += '<img src="' + img + '"/>'
                        })
                        return value ? html : value
                    } else {
                        return value ? '<img src="' + value + '"/>' : value
                    }

                default:
                    return value
            }
        },
        preview(e, value, type) {
            if (type != undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            switch (parseInt(type)) {
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    this.$popup.media({ image: value })
                    break
                default:
                    break
            }
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.register {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;

    .img-container {
        @include justify-content();
        @include display-flex();
        width: max-content;

        .main-img {
            width: 30px;
            height: 30px;
        }
        .arrow-img {
            background-position: center right;
            width: 15px;
            height: 15px;
            margin-left: 35px;
            padding: 12px;
        }

        .name {
            @include justify-content();
            align-self: center;
            padding-left: 10px;
        }
        .bullet-img {
            @include align-self(center);
            @include border-radius(50%);
            background-color: $warning;
            width: 15px;
            height: 15px;
            padding-right: 10px;
        }
    }
    .arrow-container {
        @include justify-content(center);
        @include display-flex();
        width: auto;

        .arrow-img {
            background-position: center right;
            width: 15px;
            height: 15px;
            margin-left: 15px;
        }

        .bullet-img {
            @include align-self(center);
            @include border-radius(50%);
            background-color: $warning;
            width: 15px;
            height: 15px;
            padding-right: 10px;
            position: relative;
            min-width: 15px;
            left: 6px;
        }
    }

    > .content {
        padding-top: 0 !important;

        .detail {
            width: 100%;
            height: calc(100%);

            .content {
                overflow: auto;
                height: calc(100% - 60px);
            }

            .top-actions {
                @include display-flex();
                @include align-items(center);
                $width-search: 300px;
                $width-title: calc(100% - #{$width-search});
                // margin: 6px 0 10px 0;
                overflow: hidden;
                // padding: 12px;
                // padding-bottom: 10px;
                // height: 62px;
                padding-right: 0px;
                padding-bottom: 16px;
                justify-content: space-between;

                .return-btn {
                    @extend .interaction;
                    @include background($image: img('left_dark.svg'), $size: 20px);
                    @include border-radius(4px);
                    @include align-self(center);
                    background-color: #fff;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                }
                .search {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 100%;
                    background-color: #fff;
                    padding: 0 60px 0 40px;
                    border-bottom: none;
                    font-family: $text;
                    cursor: text;
                    margin: 0px;
                    margin-right: 8px;
                }

                div.edit-columns {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('settings.svg'), $size: 20px, $position: center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #fff;
                    font-family: $text;
                    cursor: pointer;
                    margin-right: 8px;
                }

                .add-report {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('add_ffffff.svg'), $size: 20px, $position: center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #1a9980;
                    font-family: $text;
                    cursor: pointer;
                }

                .titl {
                    @include align-self(center);
                    width: $width-title;
                    max-height: 80px;
                    height: auto;

                    .name {
                        // @include font-size(ml);
                        // @include font-size(m);
                        font-size: 18px;
                        line-height: 130%;
                        margin: 0;
                        font-family: $text-bold;
                        color: $neutro-s80;
                        width: calc(100% - 50px);
                        max-height: calc(100% - 24px);
                        display: inline-block;
                        padding-left: 8px;

                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .subname {
                        @include font-size(sm);
                        width: calc(100% - 50px);
                        padding: 6px 6px 0 6px;
                        display: inline-block;
                        color: #999;
                    }
                }

                .search {
                    float: right;
                    width: $width-search;
                }
            }
            .datatable-container {
                padding: 0px 0px 6px 12px;
                height: auto;
                overflow-y: scroll;
            }
            .pagination {
                width: 100%;
                padding: 6px 0px;
                padding-left: 12px;
                display: block;
                grid-column: 1 / 4;

                .item {
                    width: calc(50% - 10px);
                    height: 45px;
                    float: left;
                    @include border-radius(4px);
                    @extend .interaction;

                    &.next {
                        @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                        float: right;
                    }
                    &.prev {
                        @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                    }
                }
            }
        }
    }
    .detail.desktop {
        .arrow-img {
            margin-left: 35px;
        }
    }
    .detail.landscape {
        height: calc(100% - 8px);

        .arrow-img {
            margin-left: 35px;
        }
    }
}
</style>

<!-- PORTRAIT STYLES -->
<style lang="scss">
#content.register {
    > .content {
        // overflow: hidden !important;
        .detail.portrait {
            .top-actions {
                width: 100%;

                div.edit-columns {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('settings.svg'), $size: 20px, $position: center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    margin-left: auto;
                    margin-right: 5px !important;
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #fff;
                    font-family: $text;
                    cursor: pointer;
                    margin: 0;
                }

                .add-report {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('add_ffffff.svg'), $size: 20px, $position: center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #1a9980;
                    font-family: $text;
                    cursor: pointer;
                    margin-left: 8px;
                    margin-right: 0;
                }

                .titl {
                    @include display-flex();
                    padding: 0;
                    min-width: calc(100% - 138px);
                    max-width: 85vw;
                    display: grid;
                    justify-items: start;
                    align-items: end;
                    flex: auto;

                    @include portrait-tablet {
                        .name {
                            @include font-size(xs);
                        }
                    }
                    .name {
                        // @include font-size(xs);
                        font-size: 0.9rem;
                        line-height: 1rem;
                        font-family: $text-bold;
                        padding: 0;
                        padding-left: 8px;
                        max-height: 95%;
                        width: 100%;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .search {
                    width: 100%;
                    display: none;
                    max-height: 40px;
                    margin: 0px !important;
                }

                .edit-columns,
                .add-report {
                    min-width: 45px;
                }
            }

            .content {
                overflow: auto;
                //height: calc(100vh - 200px);
                padding: 0px !important;

                .table {
                    tbody {
                        td {
                            padding: 10px 10px;
                            height: 80px;
                            @include font-size(xs);

                            &.col-icon {
                                padding-left: 20px;
                            }
                        }
                    }

                    th.column {
                        @include font-size(xs);
                        text-transform: uppercase;
                        font-family: $text;
                        color: $neutro-s80;
                    }
                }

                .datatable-container {
                    padding: 0px;
                    height: auto;

                    .arrow-container {
                        @include justify-content(flex-end);
                    }
                }
                .pagination {
                    width: 100%;
                    padding: 0px;
                    display: block;
                    grid-column: 1 / 4;
                    height: 45px;
                    padding: 8px 6px;
                    // margin-top: 75px;
                    position: relative;
                    // top: -75px;
                    margin-bottom: 10px;

                    .item {
                        width: calc(50% - 5px);
                        height: 45px;
                        margin: 0 0px;
                        float: left;
                        @include border-radius(4px);
                        @extend .interaction;

                        &.next {
                            @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                            float: right;
                            @extend .interaction;
                        }
                        &.prev {
                            @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                        }
                    }
                }
            }
        }
    }
}
</style>
